<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <h3 class="mt-2">Inicio</h3>
        </b-col>
      </b-row>
      <section class="mb-2">
        <color-indicator :items="items" @filterEvents="filterEvents" />
        <calendar-travel :events="events" :isBusy="isBusy" @search="findDateStartAndDateEnd" />
      </section>
    </b-container>

    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-travel :record="record" :isEdit="isEdit" :isDisabled="isDisabled" @save="save" />
      </template>
    </base-modal>
  </div>
</template>

<script>
import TravelMixin from "@/mixins/TravelMixin";
// Components
import {
  BContainer,
  BCol,
  BRow,
} from "bootstrap-vue";
import BaseModal from "@/components/base/BaseModal";
import FormTravel from "../travel/components/FormTravel";
import CalendarTravel from "@/components/calendar/CalendarTravel";
import ColorIndicator from "@/components/globals/ColorIndicator";

export default {
  inject: ["travelRepository"],
  components: {
    BContainer,
    BCol,
    BRow,
    BaseModal,
    FormTravel,
    CalendarTravel,
    ColorIndicator,
  },
  mixins: [TravelMixin],
  data() {
    return {
      isCall: true,
    }
  },
  mounted() {
    window.scroll(0, 0);
  }
};
</script>
